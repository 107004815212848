import React from "react";
import { Col, Row } from "react-bootstrap";

export default function Sucursales() {
  window.scrollTo(0, 0);

  return (
    <Row className="m-0 p-0 my-5">
      <Col xs={12} lg={{ span: "8", offset: "2" }}>
        <Row
          className="m-0 p-0  mt-3"
          style={{ borderRadius: "5px", backgroundColor: "lightGray" }}
        >
          <Col className="py-1">
            <h3>Sucursales</h3>
          </Col>
        </Row>
        <Row className="m-0 p-0 mt-4">
          <Col xs={12} lg={6} className="mt-3">
            <h3>Administracion y Ventas</h3>
            <br /> Av. Fco. Beiro 4431 (C1419HZG) Ciudad de Buenos Aires,
            <br />
            Argentina 4505-8700
            <br />
            <b>Administración</b>
            <br />
            Lun a Vie 8:30 a.18.00hs
            <br />
            <b>Ventas</b>
            <br />
            Lun a Sab 9 a 19hs Dom Cerrado
          </Col>
          <Col xs={12} lg={6} className="d-flex align-items-center mt-3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.901409883!2d-58.51556008418987!3d-34.60665446516173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb7d23df06179%3A0xa60ae2c0ba888deb!2sAv.+Francisco+Beir%C3%B3+4431%2C+Cdad.+Aut%C3%B3noma+de+Buenos+Aires!5e0!3m2!1ses!2sar!4v1482945670600"
              width="100%"
              height="200"
              frameborder="0"
              style={{ border: "0" }}
              allowfullscreen
              title="Av. Francisco Beiró 4431, Cdad. Autónoma de Buenos Aires"
            />
          </Col>
        </Row>
        <hr />
        <Row className="m-0 p-0 mt-4">
          <Col xs={12} lg={6} className="mt-3">
            <h3>Venta 0km</h3>
            <br /> Av. Fco. Beiro 3502 (C1419HYW) Ciudad de Buenos Aires,
            <br />
            Argentina 4505-8686
            <br />
            <b>Ventas</b>
            <br />
            Lun a Sab 9 a 19hs
            <br />
            Dom Cerrado
          </Col>
          <Col xs={12} lg={6} className="d-flex align-items-center mt-3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.167257523326!2d-58.504423684190094!3d-34.59993186480589!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb63186a2d225%3A0x279f6533035a97fb!2sAv.+Francisco+Beir%C3%B3+3502%2C+Cdad.+Aut%C3%B3noma+de+Buenos+Aires!5e0!3m2!1ses!2sar!4v1482946307923"
              width="100%"
              height="200"
              frameborder="0"
              style={{ border: "0" }}
              allowfullscreen
              title="Av. Francisco Beiró 3502, Cdad. Autónoma de Buenos Aires"
            />
          </Col>
        </Row>
        <hr />
        <Row className="m-0 p-0 mt-4">
          <Col xs={12} lg={6} className="mt-3">
            <h3>Ventas Usados</h3>
            <br /> Av. Fco. Beiro 4495 (C1419HYW) Ciudad de Buenos Aires,
            <br />
            Argentina 4504-8216
            <br />
            <b>Ventas</b>
            <br />
            Lun a Sab 9 a 19hs
            <br />
            Dom Cerrado
          </Col>
          <Col xs={12} lg={6} className="d-flex align-items-center mt-3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.885060355437!2d-58.51640908418984!3d-34.607067865183666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb7d220e2ead3%3A0x17b3175c8b0d321d!2sAv.+Francisco+Beir%C3%B3+4495%2C+Cdad.+Aut%C3%B3noma+de+Buenos+Aires!5e0!3m2!1ses!2sar!4v1482946461515"
              width="100%"
              height="200"
              frameborder="0"
              style={{ border: "0" }}
              allowfullscreen
              title="Av. Francisco Beiró 4495, Cdad. Autónoma de Buenos Aires"
            />
          </Col>
        </Row>
        <hr />
        <Row className="m-0 p-0 mt-4">
          <Col xs={12} lg={6} className="mt-3">
            <h3>Servicio Toyota</h3>
            <br /> Llavallol 3331 (C1417AFA) Ciudad de Buenos Aires,
            <br />
            Argentina 4505-8632
            <br />
            <b>Servicio</b>
            <br />
            Lun a Vie de 8 a 13 y 14 a 18hs
            <br />
            Sab y Dom Cerrado
          </Col>
          <Col xs={12} lg={6} className="d-flex align-items-center mt-3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.1310287249908!2d-58.501789084190044!3d-34.60084806485444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb630317c0967%3A0x4e4e06b90dc552fe!2sLlavallol+3331%2C+C1417AFA+CABA!5e0!3m2!1ses!2sar!4v1482946610225"
              width="100%"
              height="200"
              frameborder="0"
              style={{ border: "0" }}
              allowfullscreen
              title="Llavallol 3331, C1417AFA CABA"
            />
          </Col>
        </Row>
        <hr />
        <Row className="m-0 p-0 mt-4">
          <Col xs={12} lg={6} className="mt-3">
            <h3>Servicio Toyota</h3>
            <br /> Pedro, Calderón de la Barca 3036 (C1417AFA) Ciudad de Buenos
            Aires,
            <br />
            Argentina 4505-8632
            <br />
            <b>Servicio</b>
            <br />
            Lun a Vie de 8 a 13 y 14 a 18hs
            <br />
            Sab y Dom Cerrado
          </Col>
          <Col xs={12} lg={6} className="d-flex align-items-center mt-3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.6074719705216!2d-58.524597784163625!3d-34.61408606555509!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb7d99859c02d%3A0x441c2b5509b170e8!2sPedro%2C%20Pedro%20Calder%C3%B3n%20de%20la%20Barca%203036%2C%20C1417%20Villa%20Devoto%2C%20Buenos%20Aires!5e0!3m2!1sen!2sar!4v1671634662933!5m2!1sen!2sar"
              width="100%"
              height="200"
              frameborder="0"
              style={{ border: "0" }}
              allowfullscreen
              title="Pedro Calderón de la Barca 3036, C1417 Villa Devoto, Buenos Aires"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
