import React, { useState } from "react";
import { Col, Image, Row, Spinner } from "react-bootstrap";

import { useLocation, useHistory, Link } from "react-router-dom";
import useSeleccionServicio from "./hooks/useSeleccionServicio";

export default function SeleccionSucursal() {
  const { items, handleSeleccionSucursal } = useSeleccionServicio();
  const [spinner, setSpinner] = useState(false);

  const location = useLocation();
  let history = useHistory();

  const product = location.state
    ? location.state.servicioSeleccionado
    : items.length > 0
    ? items[0]
    : history.push("/servicios");

  window.scrollTo(0, 0);

  const handleSpinner = () => {
    setTimeout(() => {
      setSpinner(true);
    }, 1000);
  };
  handleSpinner();

  //console.log(product, "product desde seleccion sucursal");

  return (
    <Row className="m-0 p-0 mt-5 mb-5">
      <Col xs={12} md={{ span: 10, offset: 1 }} className="mb-5">
        <h2>Selecciona donde queres realizar el servicio</h2>
        {spinner ? (
          <Row className="m-0 p-0 mt-5 justify-content-center">
            <Col xs={12} md={4} className="card m-0 p-0 mt-3">
              <Link
                to={{
                  pathname: "/servicios/seleccionTurno",
                  state: {
                    sucursalSeleccionada: product && {
                      ...product,
                      sucursal: "P. Calderon de la Barca 3036",
                    },
                  },
                }}
                style={{ color: "black" }}
                onClick={() =>
                  handleSeleccionSucursal("P. Calderon de la Barca 3036")
                }
              >
                <Image
                  src="https://store.autoprana.com.ar/img/imgSitio/1.jpg"
                  alt="foto sucursal calderon"
                  style={{ border: "1px solid lightGrey" }}
                  width="100%"
                />
              </Link>
              <Row className="m-0 p-0 justify-content-center">
                <a
                  href="https://goo.gl/maps/wjq9DtmUdfgbG4CV6"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "black" }}
                >
                  <Col
                    xs={12}
                    className="text-center d-flex align-items-center"
                    style={{ height: "35px" }}
                  >
                    Ver en Google Maps
                  </Col>
                </a>
              </Row>
            </Col>

            <Col xs={12} md={1} className="m-0 p-0"></Col>

            <Col xs={12} md={4} className="card m-0 p-0 mt-3">
              <Link
                to={{
                  pathname: "/servicios/seleccionTurno",
                  state: {
                    sucursalSeleccionada: product && {
                      ...product,
                      sucursal: "Lavallol 3331",
                    },
                  },
                }}
                style={{ color: "black" }}
                onClick={() => handleSeleccionSucursal("Lavallol 3331")}
              >
                <Image
                  src="https://store.autoprana.com.ar/img/imgSitio/2.jpg"
                  alt="foto sucursal lavallol"
                  style={{ border: "1px solid lightGrey" }}
                  width="100%"
                />
              </Link>
              <Row className="m-0 p-0 justify-content-center">
                <a
                  href="https://goo.gl/maps/jvneHF6wCt53h77s9"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "black" }}
                >
                  <Col
                    xs={12}
                    className="text-center d-flex align-items-center"
                    style={{ height: "35px" }}
                  >
                    Ver en Google Maps
                  </Col>
                </a>
              </Row>
            </Col>
          </Row>
        ) : (
          <>
            <Row className="m-0 p-0 my-5 d-flex justify-content-center">
              <Col xs={{ span: 2, offset: 1 }}>
                <Spinner animation="border" />
              </Col>
            </Row>
          </>
        )}
      </Col>
    </Row>
  );
}
