import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

import { Row, Col, Image } from "react-bootstrap/";

import { uiContext } from "../../contexts/UiContextProvider";

//Breakpoints
import { HideAt } from "react-with-breakpoints";

import { NavLink } from "react-router-dom";

export default function SideBar() {
  const uiManager = useContext(uiContext);

  let height = window.innerHeight;
  let footerHeight = (32.8 * height) / 100;

  return (
    <ProSidebar
      collapsed={uiManager.sideBar.collapsed}
      breakPoint={"md"}
      toggled={uiManager.sideBar.toggled}
      onToggle={uiManager.sideBar.hide}
      rtl={true}
    >
      <SidebarHeader
        style={{
          /* overflow: "hidden", */
          color: "black",
          borderLeft: "1px solid lightGrey",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Row className="m-0 p-0">
          <Col
            style={{ paddingTop: "30px", color: "black", height: "80px" }}
            onClick={uiManager.sideBar.hide}
          >
            <FontAwesomeIcon icon="times-circle" className="shake" />
          </Col>
        </Row>
      </SidebarHeader>

      <SidebarContent
        style={{
          borderLeft: "1px solid lightGrey",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Menu>
          <MenuItem>
            <a
              href="https://store.autoprana.com.ar/vtf/"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              0km
            </a>
          </MenuItem>
          <MenuItem>
            <NavLink
              to="/usados"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              Usados
            </NavLink>
          </MenuItem>
          {/*   <MenuItem>
            <a
              href="https://store.autoprana.com.ar/cotizador/"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              Cotizá tu usado
            </a>
          </MenuItem> */}
          {/* <MenuItem>
            <NavLink
              to="/planes"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              Planes
            </NavLink>
          </MenuItem> */}
          <MenuItem>
            <a
              href="https://toyotaplan.com.ar/"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              Planes
            </a>
          </MenuItem>
          <MenuItem>
            <NavLink
              to="/servicios"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              Servicios
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink
              to="/accesorios"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              Accesorios
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink
              to="/repuestos"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              Repuestos
            </NavLink>
          </MenuItem>
          <hr></hr>
          <MenuItem
            icon={
              <Image
                width="25px"
                src="https://www.autoprana.com.ar/images/logos/kinto-mobility-service.svg"
              />
            }
          >
            <NavLink
              to="/kinto"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              KINTO
            </NavLink>
          </MenuItem>
          <MenuItem
            icon={
              <Image src="https://www.autoprana.com.ar/images/logos/clubToyota.png" />
            }
          >
            <a
              href="https://www.clubtoyota.com.ar/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "black" }}
            >
              Club Toyota
            </a>
          </MenuItem>
          <MenuItem icon={<FontAwesomeIcon icon="envelope" />}>
            <NavLink
              to={{
                pathname: "/contacto",
                tipoConsulta: "Consulta general",
              }}
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              Contacto
            </NavLink>
          </MenuItem>
          <MenuItem icon={<FontAwesomeIcon icon="car" />}>
            <NavLink
              to={{
                pathname: "/contacto",
                tipoConsulta: "Test Drive",
              }}
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              Test Drive
            </NavLink>
          </MenuItem>
          <MenuItem
            icon={
              <Image src="https://www.autoprana.com.ar//images/logos/grMini.png" />
            }
          >
            <a
              href="https://www.toyotagazooracing.com.ar/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "black" }}
            >
              Gazoo Racing
            </a>
          </MenuItem>
          <MenuItem
            icon={
              <Image src="https://www.autoprana.com.ar/images/logos/hybridMini.png" />
            }
          >
            <a
              href="https://www.toyota.com.ar/descubri/electrificacion/hibridos"
              target="_blank"
              rel="noreferrer"
              style={{ color: "black" }}
            >
              Híbridos
            </a>
          </MenuItem>
          <MenuItem
            icon={
              <Image src="https://www.autoprana.com.ar/images/logos/sense.png" />
            }
          >
            <a
              href="https://www.toyota.com.ar/descubri/toyota-safety-sense"
              target="_blank"
              rel="noreferrer"
              style={{ color: "black" }}
            >
              Toyota Safety Sense
            </a>
          </MenuItem>
          <MenuItem
            icon={
              <Image
                src="https://www.autoprana.com.ar/images/logos/toyotagreen.png"
                style={{ width: "25px" }}
              />
            }
          >
            <NavLink
              to="/politicaAmbiental"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              SGA
            </NavLink>
          </MenuItem>
          <MenuItem
            icon={
              <Image
                src="https://www.autoprana.com.ar/images/logos/gri.png"
                style={{ width: "25px" }}
              />
            }
          >
            <a
              href="https://www.autoprana.com.ar/images/homeNews/sustentabilidad.pdf"
              target="_blank"
              rel="noreferrer"
              style={{ color: "black" }}
            >
              Sustentabilidad
            </a>
          </MenuItem>
        </Menu>
        <HideAt breakpoint="mediumAndAbove"></HideAt>
      </SidebarContent>
      <SidebarFooter
        className="dynamicFooter"
        style={{ backgroundColor: "#f5f5f5", height: footerHeight }}
      ></SidebarFooter>
    </ProSidebar>
  );
}
