import { useState, useEffect } from "react";

export default function usePlanes() {
  const [modelos, setModelos] = useState([]);
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    setSpinner(true);
    var url = "https://store.autoprana.com.ar/pranaapi/planes";

    fetch(url)
      .then((data) => {
        return data.json();
      })
      .then((modelos) => {
        setModelos(modelos.data);
        setSpinner(false);
      });
  }, []);

  const handleButtonClick = (product) => {
    localStorage.setItem("items", JSON.stringify(product));
    /* localStorage.clear(); */
  };

  return { modelos, spinner, handleButtonClick };
}
