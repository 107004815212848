import React, { useContext } from "react";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { uiContext } from "../../contexts/UiContextProvider";

import { Row, Col } from "react-bootstrap/";

import { ShowAt } from "react-with-breakpoints";

import Image from "react-bootstrap/Image";
import logoPranaImg from "../../assets/images/logoPrana.png";

export default function NavigationBar() {
  const uiManager = useContext(uiContext);

  return (
    <Navbar
      /* fixed="top" */
      className="shadow-sm text-center"
      style={{ height: "60px", backgroundColor: "white" }}
    >
      <Navbar.Brand>
        <Link to="/" className="text-dark">
          <Col className="pl-0">
            <Image width="140px" src={logoPranaImg} />
          </Col>
        </Link>
      </Navbar.Brand>
      <Nav className="mr-auto"></Nav>
      <ShowAt breakpoint="largeAndAbove">
        <Row
          className="m-0 p-0 justify-content-center font-weight-bold"
          style={{ fontSize: "15px" }}
        >
          <Col>
            <a href="https://store.autoprana.com.ar/vtf/" className="text-dark">
              0km
            </a>
          </Col>
          <Link to="/usados" className="text-dark">
            <Col> Usados</Col>
          </Link>

          {/*  <Col>
            <a
              href="https://store.autoprana.com.ar/cotizador/"
              className="text-dark"
              style={{ whiteSpace: "nowrap" }}
            >
              Cotizá tu usado
            </a>
          </Col> */}
          {/* <Link to="/planes" className="text-dark">
            <Col> Planes</Col>
          </Link> */}
          <Col>
            <a href="https://toyotaplan.com.ar/" className="text-dark">
              Planes
            </a>
          </Col>

          <Link to="/servicios" className="text-dark">
            <Col> Servicios</Col>
          </Link>
          <Col>
            <Link to="/accesorios" className="text-dark">
              Accesorios
            </Link>
          </Col>
          <Col>
            <Link to="/repuestos" className="text-dark">
              Repuestos
            </Link>
          </Col>
          <Col>
            <Link to="/kinto" className="text-dark">
              Kinto
            </Link>
          </Col>
          <Col>
            <a
              href="https://www.clubtoyota.com.ar/"
              target="_blank"
              rel="noreferrer"
              className="text-dark"
              style={{ whiteSpace: "nowrap" }}
            >
              Club Toyota
            </a>
          </Col>
        </Row>

        <Nav className="mr-auto"></Nav>
        {/* <Navbar.Brand>
          <a href="https://store.autoprana.com.ar/" style={{ color: "black" }}>
            <Row className="m-0 p-0">
              <Col xs={2} className="m-0 p-0">
                <a
                  href="https://store.autoprana.com.ar/politicaAmbiental.php"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    width="35px"
                    src="https://store.autoprana.com.ar/img/imgSitio/logos/toyotagreen.png"
                  />
                </a>
              </Col>
              <Col xs={10}>
                <a
                  href="https://www.toyota.com.ar/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    width="160px"
                    src="https://store.autoprana.com.ar/img/imgSitio/logos/logoInst.jpg"
                  />
                </a>
              </Col>
            </Row>
          </a>
        </Navbar.Brand> */}
      </ShowAt>
      <Nav>
        <Navbar.Brand
          style={{ fontSize: "17px", paddingTop: "10px", marginRight: "5px" }}
        >
          Menu
        </Navbar.Brand>
        <Button
          className="d-block d-md-none without-focus-border"
          variant="transparent"
          onClick={uiManager.sideBar.show}
          style={{ paddingTop: "10px" }}
        >
          <FontAwesomeIcon icon="bars" style={{ color: "black" }} />
        </Button>
        <Button
          className="d-none d-md-block btn-outline-light"
          variant="transparent"
          onClick={uiManager.sideBar.toggleCollapsed}
          style={{ paddingTop: "10px" }}
        >
          <FontAwesomeIcon icon="bars" style={{ color: "black" }} />
        </Button>
        <ShowAt breakpoint="mediumAndAbove"></ShowAt>
      </Nav>
    </Navbar>
  );
}
