import React, { useContext, useEffect } from "react";
import useKinto from "./hooks/useKinto";
import { Card, Col, Image, Row, Accordion, Spinner } from "react-bootstrap";

import acercaHero from "../../assets/images/secciones/kinto/acerca-hero.jpg";
import kintoLogo from "../../assets/images/secciones/kinto/kintoLogo.png";
import kintoOneLogo from "../../assets/images/secciones/kinto/kintoOneLogo.png";
import kintoShareLogo from "../../assets/images/secciones/kinto/kintoShareLogo.png";
import kintoPersonalLogo from "../../assets/images/secciones/kinto/kintoPersonalLogo.png";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import CustomSelect from "../tarjetas/customInput/CustomSelect";
import CustomInput from "../tarjetas/customInput/CustomInput";
import CustomTextArea from "../tarjetas/customInput/CustomTextArea";
import { uiContext } from "../../contexts/UiContextProvider";

import $ from "jquery";

export default function Kinto() {
  const {
    modelos,
    options,
    advanceSchema,
    onSubmit,
    kintoProducts,
    justletterRegex,
    numberRegex,
    spinner,
  } = useKinto();
  const baseUrl = "https://store.autoprana.com.ar/img/";
  //console.log(modelos, "modelos");

  const { sideBar } = useContext(uiContext);

  //funcion necesaria para que OWL carousel funcione con REACT-PROSIDEBAR
  const reSize = () => {
    if (!sideBar.collapsed) {
      $(".owl-stage-outer").width($(".owl-stage-outer").width() - 250); //el 250 es el ancho del sidebar
    } /* else {
      $(".owl-stage-outer").width($(".owl-stage-outer").width() - 250);
      setTimeout(() => {
        $(".owl-stage-outer").width($(".owl-stage-outer").width() + 250);
      }, 200);
    } */
  };

  useEffect(reSize, [sideBar.collapsed]);

  return (
    <>
      <Row className="m-0 p-0 mt-4">
        <Col className="my-5 ml-3">
          <b className="mr-3">
            <Image src={kintoLogo} width="50px" className="pb-1" />
          </b>
          |{" "}
          <span className="ml-3">
            Soluciones de movilidad integrales e inteligentes
          </span>
        </Col>
      </Row>
      <Row className="m-0 p-0 mb-3">
        <Col>
          <Accordion defaultActiveKey="0">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <Image src={kintoShareLogo} width="100px" />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <b>CAR SHARING</b>
                  <br /> <Image src={kintoShareLogo} width="100px" /> es la
                  solución de movilidad más conveniente, inteligente y confiable
                  que se adapta a tus necesidades. Alquilá un Toyota por el
                  tiempo que quieras y en el lugar que más te convenga.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="1">
                <Image src={kintoOneLogo} width="105px" className="pl-1" />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  {" "}
                  <b>ALQUILER TODO INCLUIDO</b>
                  <br />
                  <Image src={kintoOneLogo} width="105px" /> busca ser tu socio
                  estratégico ofreciéndole a tu empresa una solución de
                  movilidad mediante el alquiler de vehículos a mediano y largo
                  plazo con todos los servicios incluidos. Te proveemos de
                  unidades 0Km, incluyendo la gestión de la flota, seguros,
                  mantenimiento, documentación y telemetría. Te acompañaremos en
                  tus proyectos con la garantía, calidad y cobertura nacional
                  que sólo Toyota te puede ofrecer.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="2">
                <Image src={kintoPersonalLogo} width="120px" className="pl-1" />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  {" "}
                  <b>SUSCRIPCIÓN FLEXIBLE</b>
                  <br />
                  <Image src={kintoPersonalLogo} width="120px" /> es una opción
                  dirigida a las personas interesadas en el uso de vehículos
                  bajo la modalidad de suscripción mensual. Este nuevo servicio,
                  le ofrece a los clientes el uso de un vehículo a mediano y
                  largo plazo, con la posibilidad de acceder a días adicionales
                  de uso de cualquier vehículo disponible en la plataforma de
                  KINTO SHARE, en cualquier momento que el cliente lo disponga.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
      <Row className="m-0 p-0 mb-4">
        <Col className="m-0 p-0">
          <Image
            src={acercaHero}
            style={{ objectFit: "cover", width: "100%", height: "30vh" }}
          />
        </Col>
      </Row>

      <Row className=" m-0 my-4 p-0 text-center">
        <Col className="m-0 p-0">
          <span>
            Nuesta flota de vehículos{" "}
            <Image src={kintoLogo} width="50px" className="pb-1" /> está
            compuesta por los siguientes modelos:
          </span>
          <Row className="m-0 p-0">
            {!spinner ? (
              <OwlCarousel className="owl-theme" {...options}>
                {modelos.map((modelo, i) => (
                  <Col
                    xs={12}
                    md={{ span: "11", offset: "1" }}
                    key={i + 1}
                    className="my-3 p-0"
                  >
                    <Card className="item">
                      <Row className="m-0 p-0">
                        <Col
                          xs={12}
                          style={{ backgroundColor: "#f5f5f5" }}
                          className="py-2"
                        >
                          <b className="pt-3">
                            {modelo.Modelo.substring(
                              0,
                              modelo.Modelo.indexOf(" ")
                            )}
                            <br />
                            {modelo.Modelo.substring(
                              modelo.Modelo.indexOf(" ") + 1
                            )}
                          </b>
                          <br />
                          <i style={{ fontSize: "12px" }}>{modelo.Tipo}</i>
                          <br />
                          <FontAwesomeIcon icon="calendar" /> {""}
                          {modelo.Anio}
                          <br />
                          <FontAwesomeIcon icon="gas-pump" />{" "}
                          {modelo.Combustible}
                          <br />
                          <FontAwesomeIcon icon="car-side" />{" "}
                          {modelo.Transmision}
                        </Col>
                      </Row>
                      <Image
                        src={baseUrl + modelo.Foto}
                        style={{
                          objectFit: "contain",
                          height: "250px",
                          maxHeight: "250px",
                          minHeight: "250px",
                          width: "100%",
                        }}
                      />
                      <Row className="m-0 p-0">
                        <Col
                          style={{
                            fontSize: "12px",
                            backgroundColor: "#63bac4",
                            color: "white",
                          }}
                          className="py-3"
                        >
                          Todas las unidades cuentas con: Aire acondicionado,
                          ABS, Airbag, control de tracción, cierre centralizado,
                          levantavidrios eléctricos.
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                ))}
              </OwlCarousel>
            ) : (
              <>
                <Col
                  xs={12}
                  className="text-center mx-0 my-5"
                  style={{ marginTop: "10px" }}
                >
                  <Spinner animation="grow" variant="secondary" />
                </Col>
              </>
            )}
          </Row>
          <Row className="m-0 p-0">
            <Col className="">
              <b>consultá precios y disponibilidad</b>
            </Col>
          </Row>
          {/* <Row className="m-0 p-0">
            <Col md={{ span: "8", offset: "2" }} className="">
              <Formik
                initialValues={{
                  nombre: "",
                  modelo: "",
                  correo: "",
                  telefono: "",
                  kintoProducts: "",
                  consulta: "",
                }}
                validationSchema={advanceSchema}
                onSubmit={onSubmit}
              >
                {({ isSubmitting, values, setFieldValue }) => (
                  <Form autoComplete="off">
                    <CustomSelect
                      label={
                        <span>
                          Producto{" "}
                          {
                            <Image
                              src={kintoLogo}
                              width="50px"
                              className="pb-1"
                            />
                          }
                        </span>
                      }
                      name="kintoProducts"
                      kintoProducts={kintoProducts}
                    >
                      <option>Tipo de alquiler</option>
                      {kintoProducts.map((product, i) => (
                        <option value={product.name} key={i}>
                          {product.name}
                        </option>
                      ))}
                    </CustomSelect>
                    <CustomSelect
                      label="Modelo"
                      name="modelo"
                      kintoProducts={kintoProducts}
                    >
                      <option>Modelo</option>
                      {modelos.map((modelo, i) => (
                        <option value={modelo.Modelo} key={i}>
                          {modelo.Modelo}
                        </option>
                      ))}
                    </CustomSelect>
                    <CustomInput
                      label="Nombre y apellido"
                      name="nombre"
                      type="text"
                      placeholder="Ingrese nombre y apellido"
                      value={values.nombre}
                      onChange={(e) => {
                        e.preventDefault();
                        const { value } = e.target;
                        if (
                          !value ||
                          justletterRegex.test(value.slice(-1).toString())
                        ) {
                          setFieldValue("nombre", value);
                        }
                      }}
                    />

                    <CustomInput
                      label="e-mail"
                      name="correo"
                      type="text"
                      placeholder="ingrese su email"
                      value={values.correo}
                    />
                    <CustomInput
                      label="Telefono"
                      name="telefono"
                      type="text"
                      placeholder="prefijo y numero"
                      value={values.telefono}
                      onChange={(e) => {
                        e.preventDefault();
                        const { value } = e.target;
                        if (!value || numberRegex.test(value.toString())) {
                          setFieldValue("telefono", value);
                        }
                      }}
                    />
                    <CustomTextArea
                      label="Consulta"
                      name="consulta"
                      type="text"
                      placeholder="ingrese su consulta"
                      value={values.consulta}
                    />
                    <Row className="m-0 p-0 mt-5 mb-4">
                      <Col xs={12} className="text-center">
                        <button
                          className="btn btn-primary"
                          disabled={isSubmitting}
                          type="submit"
                          style={{ width: "100%" }}
                        >
                          {isSubmitting ? "Procesando..." : "Enviar"}
                        </button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row> */}
        </Col>
      </Row>
      <Row
        className="m-0 p-0 my-5 text-center"
        style={{ backgroundColor: "#007084" }}
      >
        <Col
          md={{ span: "8", offset: "2" }}
          style={{ color: "white", lineHeight: "2", fontSize: "15px" }}
        >
          {/* También podes hacer tu consulta por: <br /> */}
          {/* TAMBIÉN PODES HACER TU CONSULTA POR: <br /> */}
          <FontAwesomeIcon icon="phone" /> 4505-8700 int 9248 <br />
          <FontAwesomeIcon icon="mobile" /> 11-4404-6346
        </Col>
      </Row>
    </>
  );
}
