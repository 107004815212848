import { useEffect, useState } from "react";
import { Card, Col, Spinner } from "react-bootstrap";
import { imagPathUsados } from "../../../constants/constants";

export default function useFotos({ product }) {
  const [show, setShow] = useState(false);
  const [imageToShow, setImageToShow] = useState("");
  const [arrayFotos, setArrayFotos] = useState([]);
  const [loading, setLoading] = useState([]);

  //console.log(loading);

  const imageToShowFn = (e) => {
    setImageToShow(e.target.src);
  };

  useEffect(() => {
    let arrayFotos = [];
    for (const key in product) {
      key.includes("foto") && (arrayFotos = [...arrayFotos, key]);
    }
    setArrayFotos(arrayFotos);
    setLoading(Array(arrayFotos.length).fill(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const renderFotos = () => {
    return arrayFotos.map((foto, i) => (
      <Col sm={5} md={5} lg={3} xl={3} className="m-1" key={"id" + i}>
        <Card>
          <Col
            style={{ display: loading[i] ? "block" : "none" }}
            className="text-center"
          >
            <Spinner animation="grow" variant="secondary" />
          </Col>
          <Card.Img
            variant="top"
            style={{
              maxWidth: "100%",
              maxHeight: "14rem",
              minHeight: "14rem",
              objectFit: "cover",
              display: loading[i] ? "none" : "block",
            }}
            src={imagPathUsados + product[foto]}
            onClick={(e) => {
              setShow(true);
              imageToShowFn(e);
            }}
            onLoad={() =>
              setLoading((loading) =>
                loading.map((item, j) => (j === i ? false : item))
              )
            }
          />
        </Card>
      </Col>
    ));
  };

  return { renderFotos, show, setShow, imageToShow };
}
