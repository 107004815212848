import React from "react";

import useUsados from "./hooks/useUsados";

import { Row, Col, Spinner, Image, Form } from "react-bootstrap/";

import { capitalize, MARCAS } from "../../constants/constants";

export default function Catalogo() {
  const {
    spinner,
    genericCardstoRender,
    buttonsToRender,
    marcaSeleccionada,
    handleChangeFilter,
    search,
  } = useUsados();

  window.scrollTo(0, 0);

  //console.log(product, "product desde catalogo");
  //console.log(items[0], "items desde catalogo");

  return (
    <>
      <Row className="m-0 mt-5 mb-3 p-0 justify-content-center">
        {marcaSeleccionada === MARCAS.TOYOTA_CERTIFICADO ? (
          <Col className="text-left p-2" xs={12} md={{ span: 4 }}>
            <Image
              className="mb-4"
              style={{ width: "90%", borderRadius: "5px" }}
              src="https://store.autoprana.com.ar/img/imgSitio/logos/usadosSele.jpg"
            ></Image>
          </Col>
        ) : (
          ""
        )}
        <Col
          className="text-center mb-1 d-flex justify-content-center align-items-center"
          lg={9}
          style={{
            backgroundColor: "red",
            fontSize: "2rem",
            borderRadius: "5px",
            color: "black",
            flexWrap: "wrap",
          }}
        >
          {marcaSeleccionada && buttonsToRender()}
        </Col>
        <Col
          className="text-left pl-4 mb-1"
          lg={9}
          style={{
            backgroundColor: "black",
            fontSize: "2rem",
            borderRadius: "5px",
            color: "black",
          }}
        >
          <Row className="m-0 p-0" style={{ height: "40px" }}>
            <Col
              xs={{ span: 10, offset: 1 }}
              md={{ span: 6, offset: 3 }}
              lg={{ span: 4, offset: 4 }}
              className="text-center mt-1"
              style={{ color: "white" }}
            >
              <Form.Group>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="buscar"
                  value={search}
                  onChange={(e) => handleChangeFilter(e)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col
          className="text-left p-1 pl-4"
          lg={9}
          style={{
            backgroundColor: "darkgray",
            fontSize: "1.5rem",
            borderRadius: "5px",
            color: "black",
          }}
        >
          usados{" "}
          {marcaSeleccionada &&
            (marcaSeleccionada === MARCAS.OTRAS
              ? marcaSeleccionada.toLowerCase()
              : capitalize(marcaSeleccionada))}
        </Col>
      </Row>
      <Row className="m-0 p-0 mb-5 justify-content-center text-left">
        {!spinner ? (
          genericCardstoRender()
        ) : (
          <>
            <Row className="mx-0" style={{ marginTop: "2%" }}>
              <Col xs={12} className="text-center">
                Cargando...
              </Col>
              <Col
                xs={12}
                className="text-center mx-0"
                style={{ marginTop: "10px" }}
              >
                <Spinner animation="grow" variant="secondary" />
              </Col>
            </Row>
          </>
        )}
      </Row>
    </>
  );
}
