import { useState, useEffect } from "react";

export default function useSeleccionServicio() {
  const [items, setItems] = useState(localStorage);
  const [spinner, setSpinner] = useState(false);
  const [servicio, setServicio] = useState([]);
  const [servicioSeleccionado, setServicioSeleccionado] = useState(null);

  //console.log("🚀 ~ servicios", servicios);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("items"));
    if (items) {
      setItems([items]);
    }
  }, []);

  let km = servicioSeleccionado?.slice(0, -3);
  let modelo = items[0]?.modelo;
  let motor = items[0]?.motor;

  useEffect(() => {
    setSpinner(true);
    var url = `https://store.autoprana.com.ar/pranaapi/servicios/seleccionServicio/km/${km}/modelo/${modelo}/motor/${motor}`;

    if (servicioSeleccionado !== null) {
      fetch(url)
        .then((data) => {
          return data.json();
        })
        .then((modelos) => {
          setServicio(modelos.data);
          setSpinner(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servicioSeleccionado]);

  const kilometrajes = () => {
    let kilometrajes = [];
    for (let i = 1; i <= 15; i++) {
      let valor = i * 10000;
      kilometrajes.push(
        valor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " kms"
      );
    }
    return kilometrajes;
  };

  const onChange = (e) => {
    setServicioSeleccionado(e.target.value);
  };

  const handleSeleccionSucursal = (sucursal) => {
    let obj = items[0];
    obj.sucursal = sucursal;
    localStorage.setItem("items", JSON.stringify(obj));
    //console.log(items[0], "servicioSeleccionado");
  };

  const handleButtonClick = (servicio) => {
    servicio.origen = "servicios";
    localStorage.setItem("items", JSON.stringify(servicio));
    /* localStorage.clear(); */
  };

  const handleSeleccionTurno = (day, time) => {
    let obj = items[0];
    obj.turno = day + " " + time;
    localStorage.setItem("items", JSON.stringify(obj));
    //console.log(obj);
  };

  return {
    items,
    servicio,
    spinner,
    kilometrajes,
    onChange,
    servicioSeleccionado,
    handleSeleccionSucursal,
    handleButtonClick,
    handleSeleccionTurno,
  };
}
