import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import useSeleccionServicio from "./hooks/useSeleccionServicio";

import MyDatepickerComponent from "./datePicker/MyDatepickerComponent";
import { Button, Col, Row } from "react-bootstrap";

import useSeleccionTurno from "./hooks/useSeleccionTurno";

export default function SeleccionTurno() {
  const [fixShowTimer, setFixShowTimer] = useState(true);

  const { items, handleSeleccionTurno } = useSeleccionServicio();

  const location = useLocation();
  let history = useHistory();

  const product = location.state
    ? location.state.sucursalSeleccionada
    : items.length > 0
    ? items[0]
    : history.push("/servicios");

  window.scrollTo(0, 0);

  //console.log(product, "desde seleccion turno");

  const {
    horariosNoDisponibles,
    handleDateTime,
    day,
    time,
    handleShow,
    show,
    rawDateTime,
    handleTime,
  } = useSeleccionTurno({
    product,
    items,
  });

  useEffect(() => {
    setFixShowTimer(false);
  }, [show]);

  useEffect(() => {
    if (fixShowTimer === false) {
      setFixShowTimer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixShowTimer]);

  return (
    <>
      <Row className="m-0 p-0 mt-5 mb-5">
        <Col xs={12} md={{ span: 10, offset: 1 }} className="text-center">
          <Row className="m-0 p-0">
            <Col xs={12} md={{ span: 10, offset: 1 }} className="mb-2">
              <h2>
                Selecciona el dia y horario para realizar el servicio en{" "}
                <b>{product && product.sucursal}</b>
              </h2>
            </Col>
          </Row>

          {fixShowTimer && (
            <MyDatepickerComponent
              horariosNoDisponibles={horariosNoDisponibles}
              handleDateTime={handleDateTime}
              day={day}
              handleShow={handleShow}
              show={show}
              rawDateTime={rawDateTime}
              handleTime={handleTime}
            />
          )}
        </Col>
      </Row>
      <Row className="m-0 p-0">
        <Col
          xs={12}
          md={{ span: 10, offset: 1 }}
          className="text-center"
          style={{ paddingTop: "240px", marginBottom: "20px" }}
        >
          <h4>
            {!day || !time ? "Seleccione" : ""} {!day ? "fecha y" : ""}
            {!time ? " horario" : ""}
          </h4>
          <h3>
            {day && day}
            {time && " - " + time}
          </h3>
        </Col>
      </Row>
      <Row className="m-0 p-0">
        <Col
          xs={12}
          md={{ span: 10, offset: 1 }}
          className="text-center"
          style={{ paddingTop: "10px", marginBottom: "70px" }}
        >
          {day && time && (
            <Link
              to={{
                pathname: "/tarjetas",
                state: {
                  product: product && {
                    ...product,
                    turno: day + " " + time,
                  },
                },
              }}
              style={{ color: "black" }}
              onClick={() => handleSeleccionTurno(day, time)}
            >
              <Button
                size="sm"
                variant="outline-light"
                style={{
                  backgroundColor: "red",
                  borderColor: "transparent",
                  fontWeight: "bold",
                }}
              >
                continuar
              </Button>
            </Link>
          )}
        </Col>
      </Row>
    </>
  );
}
