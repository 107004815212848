import { useState, useEffect } from "react";

export default function useFicha() {
  const [items, setItems] = useState(localStorage);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("items"));
    if (items) {
      setItems([items]);
    }
  }, []);

  return { items };
}
