import React from "react";
import { Image } from "react-bootstrap";

import whatsapp from "../../assets/images/logos/whatsapp.png";

export default function Whatsapp() {
  return (
    <>
      <a href="https://wa.me/5491145058700?text=Hola, me estoy comunicando desde el store. Mi consulta es:%20">
        <span
          className="mt-5 mb-5 d-flex align-items-center justify-content-center"
          style={{
            position: "fixed",
            top: "88.5%",
            right: "5%",
            width: "auto",
          }}
        >
          <span className="circle d-flex align-items-center justify-content-center">
            <Image
              src={whatsapp}
              alt="whatsapp logo"
              style={{
                width: "35px",
              }}
            />
          </span>
        </span>
      </a>
    </>
  );
}
