import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import useFotos from "./hooks/useFotos";

import useUsados from "./hooks/useUsados";

export default function Fotos() {
  window.scrollTo(0, 0);
  const { items } = useUsados();

  const location = useLocation();
  let history = useHistory();

  const product = location.state
    ? location.state.product
    : items.length > 0
    ? items[0]
    : history.push("/");

  const { renderFotos, show, setShow, imageToShow } = useFotos({ product });

  //console.log(product);

  return (
    <>
      <Row className="m-0 p-0 my-4 justify-content-center text-left">
        {renderFotos()}
      </Row>
      <Row className="m-0 p-0">
        <Col xs={12}>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            className="modal-large"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                {product && product.modelo}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <img
                src={imageToShow}
                alt="foto de vehiculo usado"
                style={{ width: "100%" }}
              />
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
      <Row className="m-0 p-0 mb-4">
        <Col xs={12} className="text-center">
          <Link
            to={{
              pathname: "/usados/catalogo",
              state: {
                product: product,
              },
            }}
          >
            <Button
              variant="outline-light"
              style={{
                backgroundColor: "red",
                borderColor: "transparent",
                fontWeight: "bold",
              }}
            >
              volver
            </Button>
          </Link>
        </Col>
      </Row>
    </>
  );
}
