import React from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import usadosToyota from "../../assets/images/secciones/usadosToyota.png";
import usadosOtrasMarcas from "../../assets/images/secciones/usadosOtrasMarcas.png";

import { MARCAS } from "../../constants/constants";

export default function SeleccionUsadosMarca() {
  //localStorage.clear();

  const handleButtonClick = (product) => {
    localStorage.setItem("items", JSON.stringify(product));
  };

  window.scrollTo(0, 0);

  return (
    <>
      <Row className="m-0 mb-4 p-0 pt-4 ">
        <Col xs={12} lg={{ span: "8", offset: "2" }} className="">
          <Row style={{ margin: "50px 0 50px 0" }}>
            <Col xs={12} lg={6} className="mb-5">
              <Link
                to={{
                  pathname: "/usados/catalogo",
                  state: { product: { marca: MARCAS.TOYOTA } },
                }}
                onClick={() => handleButtonClick({ marca: MARCAS.TOYOTA })}
              >
                <Col>
                  <Card style={{ border: "0" }}>
                    <Image
                      width="100%"
                      src={usadosToyota}
                      style={{ borderRadius: "5px" }}
                    />
                  </Card>
                </Col>
              </Link>
            </Col>
            <Col xs={12} lg={6}>
              <Link
                to={{
                  pathname: "/usados/catalogo",
                  state: { product: { marca: MARCAS.OTRAS } },
                }}
                onClick={() => handleButtonClick({ marca: MARCAS.OTRAS })}
              >
                <Col>
                  <Card style={{ border: "0" }}>
                    <Image
                      width="100%"
                      src={usadosOtrasMarcas}
                      style={{ borderRadius: "5px" }}
                    />
                  </Card>
                </Col>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
