import { useField } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";

export default function CustomSelect({ label, ...props }) {
  const [field, meta /* , helpers */] = useField(props);

  //console.log(field, "field");
  //console.log(meta, "meta");

  if (field.name === "tarjeta") {
    const tarjetaSeleccionada =
      field.value &&
      props.tarjetas.find(
        (tarjeta) => field.value && tarjeta.value === field.value
      );
    props.handleOnChange(tarjetaSeleccionada);
  }

  return (
    <>
      <Row className="m-0 p-0 mt-2">
        <Col>
          <label>{label}</label>
        </Col>
      </Row>
      <Row className="m-0 p-0 mb-2">
        <Col>
          <select
            {...field}
            {...props}
            className={
              meta.touched && meta.error
                ? "input-error form-control"
                : "form-control"
            }
          />
          {meta.touched && meta.error && (
            <div className="errorMessage">{meta.error}</div>
          )}
        </Col>
      </Row>
    </>
  );
}
