import React from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import useSeleccionServicio from "../servicios/hooks/useSeleccionServicio";
import { Form, Formik } from "formik";

import PaymentForm from "./cards/PaymentForm";
import useSeleccionTarjeta from "./hooks/useSeleccionTarjeta";
import CustomInput from "./customInput/CustomInput";
import CustomSelect from "./customInput/CustomSelect";
//import CustomCheckbox from "./customInput/CustomCheckbox";

export default function Tarjetas() {
  const { items } = useSeleccionServicio();

  const location = useLocation();
  let history = useHistory();

  const product = location.state
    ? location.state.product
    : items.length > 0
    ? items[0]
    : history.push("/");

  console.log(product, "desde tarjetas");

  const {
    advanceSchema,
    tarjetas,
    tarjeta,
    onSubmit,
    numberRegex,
    justletterRegex,
    focus,
    handleTurnCard,
    setFocus,
    cargaInicial,
    cuotas,
    handleCuotasPorTarjeta,
    documentos,
    handleTarjetaSeleccionada,
  } = useSeleccionTarjeta({ product });

  cargaInicial && window.scrollTo(0, 0);

  return (
    <>
      <Row className="m-0 p-0 mb-3">
        <Col xs={12} md={{ span: 10, offset: 1 }} className="mt-5">
          <Row className="m-0 p-0">
            <Col className="text-center mb-4">
              <h3>
                Monto de la compra:{" "}
                {Intl.NumberFormat("es-AR", {
                  style: "currency",
                  currency: "ARS",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(product && product.precio)}
              </h3>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="m-0 p-0">
        <Col
          xs={12}
          md={{ span: 8, offset: 2 }}
          lg={{ span: 4, offset: 4 }}
          className="mb-5"
        >
          <Formik
            initialValues={{
              tarjeta: "",
              numeroTarjeta: "",
              vencimientoMes: "",
              vencimientoAnio: "",
              codigo: "",
              cuotas: "",
              nombre: "",
              documentoTipo: "",
              documentoNumero: "",
              correo: "",
              telefono: "",
              /* terminos: false, */
            }}
            validationSchema={advanceSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form autoComplete="off">
                <Row className="m-0 p-0">
                  <Col className="mb-4">
                    <PaymentForm
                      tarjeta={values.tarjeta}
                      numeroTarjeta={values.numeroTarjeta}
                      nombre={values.nombre}
                      vencimientoMes={values.vencimientoMes}
                      vencimientoAnio={values.vencimientoAnio}
                      codigo={values.codigo}
                      focus={focus}
                      setFocus={setFocus}
                      handleTurnCard={handleTurnCard}
                    />
                  </Col>
                </Row>
                <CustomSelect
                  onSelect={() => {
                    setFocus("tarjeta");
                  }}
                  label="Tarjeta"
                  name="tarjeta"
                  placeholder="Seleccione su tarjeta"
                  handleOnChange={handleTarjetaSeleccionada}
                  tarjetas={tarjetas}
                >
                  <option>"Seleccione su tarjeta"</option>
                  {tarjetas.map((tarjeta, i) => (
                    <option
                      value={tarjeta.value}
                      method_id={tarjeta.method_id}
                      key={i}
                    >
                      {tarjeta.nombre}
                    </option>
                  ))}
                </CustomSelect>

                <CustomInput
                  onSelect={() => {
                    setFocus("numeroTarjeta");
                  }}
                  label="Numero de tarjeta"
                  name="numeroTarjeta"
                  type="text"
                  placeholder="solo numeros"
                  value={values.numeroTarjeta}
                  maxLength={values.tarjeta === "amex" ? 15 : 16}
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.target;
                    if (!value || numberRegex.test(value.toString())) {
                      setFieldValue("numeroTarjeta", value);
                    }
                  }}
                />
                <Row className="m-0 p-0">
                  <Col xs={6} className="m-0 p-0">
                    <CustomInput
                      onSelect={() => {
                        setFocus("vencimientoMes");
                      }}
                      label="Vecimiento"
                      name="vencimientoMes"
                      type="text"
                      placeholder="MM"
                      value={values.vencimientoMes}
                      maxLength="2"
                      onChange={(e) => {
                        e.preventDefault();
                        const { value } = e.target;
                        if (!value || numberRegex.test(value.toString())) {
                          setFieldValue("vencimientoMes", value);
                        }
                      }}
                    />
                  </Col>
                  <Col xs={6} className="m-0 p-0">
                    <CustomInput
                      onSelect={() => {
                        setFocus("vencimientoAnio");
                      }}
                      label="&nbsp;"
                      name="vencimientoAnio"
                      type="text"
                      placeholder="AA"
                      value={values.vencimientoAnio}
                      maxLength="2"
                      onChange={(e) => {
                        e.preventDefault();
                        const { value } = e.target;
                        if (!value || numberRegex.test(value.toString())) {
                          setFieldValue("vencimientoAnio", value);
                        }
                      }}
                    />
                  </Col>
                </Row>
                <CustomInput
                  onSelect={(e) => {
                    setFocus("cvc");
                  }}
                  label="Código de seguridad"
                  name="codigo"
                  type="text"
                  placeholder="Solo numeros"
                  value={values.codigo}
                  maxLength={values.tarjeta === "amex" ? 4 : 3}
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.target;
                    if (!value || numberRegex.test(value.toString())) {
                      setFieldValue("codigo", value);
                    }
                  }}
                />
                {tarjeta !== "visa_debito" && (
                  <CustomSelect
                    onSelect={() => {
                      setFocus("cuotas");
                    }}
                    label="Cuotas"
                    name="cuotas"
                    placeholder="Seleccione cantidad de cuotas"
                  >
                    <option>
                      {cuotas.length > 0
                        ? "cuotas"
                        : "primero seleccione una tarjeta"}
                    </option>
                    {cuotas !== "" && handleCuotasPorTarjeta(product)}
                  </CustomSelect>
                )}
                <CustomInput
                  onSelect={() => {
                    setFocus("Nombre");
                  }}
                  label="Nombre y apellido"
                  name="nombre"
                  type="text"
                  placeholder="Ingrese nombre y apellido"
                  value={values.nombre}
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.target;
                    if (
                      !value ||
                      justletterRegex.test(value.slice(-1).toString())
                    ) {
                      setFieldValue("nombre", value);
                    }
                  }}
                />
                <Row className="m-0 p-0">
                  <Col xs={6} className="m-0 p-0">
                    <CustomSelect
                      onSelect={() => {
                        setFocus("documentoTipo");
                      }}
                      label="Documento"
                      name="documentoTipo"
                    >
                      <option>Tipo</option>
                      {documentos.map((documento, i) => (
                        <option value={documento.value} key={i}>
                          {documento.nombre}
                        </option>
                      ))}
                    </CustomSelect>
                  </Col>
                  <Col xs={6} className="m-0 p-0">
                    <CustomInput
                      onSelect={() => {
                        setFocus("documentoNumero");
                      }}
                      label="&nbsp;"
                      name="documentoNumero"
                      type="text"
                      placeholder="Numero"
                      value={values.documentoNumero}
                      maxLength="10"
                      onChange={(e) => {
                        e.preventDefault();
                        const { value } = e.target;
                        if (!value || numberRegex.test(value.toString())) {
                          setFieldValue("documentoNumero", value);
                        }
                      }}
                    />
                  </Col>
                </Row>
                <CustomInput
                  onSelect={() => {
                    setFocus("correo");
                  }}
                  label="Email"
                  name="correo"
                  type="email"
                  placeholder="ingrese su email"
                  value={values.correo}
                />
                <CustomInput
                  onSelect={() => {
                    setFocus("telefono");
                  }}
                  label="Telefono"
                  name="telefono"
                  type="text"
                  placeholder="prefijo y numero"
                  value={values.telefono}
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.target;
                    if (!value || numberRegex.test(value.toString())) {
                      setFieldValue("telefono", value);
                    }
                  }}
                />
                {/* <CustomCheckbox type="checkbox" name="terminos" /> */}
                <Row className="m-0 p-0 mt-5 mb-4">
                  <Col xs={12} className="text-center">
                    <button
                      className="btn btn-primary"
                      disabled={isSubmitting}
                      type="submit"
                      style={{ width: "100%" }}
                    >
                      {isSubmitting ? "Procesando..." : "Comprar ahora"}
                    </button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
}
