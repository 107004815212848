import React from "react";

import { Col, Image, Row } from "react-bootstrap";

import cdv from "../../assets/images/logos/cdv.png";
import gri from "../../assets/images/logos/gri.png";
import { ShowAt } from "react-with-breakpoints";

export default function PolitaAmbiental() {
  return (
    <Row className="m-0 p-0 mt-5">
      <Col xs={12} lg={{ span: "8", offset: "2" }} className="mt-3 mb-5">
        <Row
          className="m-0 p-0"
          style={{ borderRadius: "5px", backgroundColor: "lightGray" }}
        >
          <Col xs={12} className="text-center pt-1">
            <h3>Ciclo de vida</h3>
          </Col>
        </Row>
        <ShowAt breakpoint="largeAndAbove">
          <Row className="m-0 p-0 mt-4">
            <Col
              xs={4}
              className="m-0 p-0 text-center d-flex align-items-center justify-content-center"
            >
              <Row className="d-flex m-0 p-0">
                <Col xs={12}>
                  Para preservar la vida útil del vehículo y fomentar el cuidado
                  del medio ambiente Toyota Prana se compromete y comunica su
                  política ambiental a todos sus clientes y proveedores.
                </Col>
                <Col xs={12} className="mt-5">
                  Durante toda la cadena de valor Toyota Prana se compromete
                  mediante una gestión responsable al cuidado y minimización de
                  los recursos. Tratando sus residuos líquidos y sólidos
                  responsablemente.
                </Col>
              </Row>
            </Col>
            <Col
              xs={4}
              className="d-flex align-items-center justify-content-center"
            >
              <Image src={cdv} width="100%" />
            </Col>
            <Col
              xs={4}
              className="m-0 p-0 d-flex align-items-center justify-content-center"
            >
              <Row className="d-flex m-0 p-0">
                <Col xs={12}>
                  Informando los beneficios del vehículo Toyota Argentina S.A.
                  recomienda al cliente gestionar el mantenimiento del mismo en
                  concesionarias oficiales de la red, en tiempo y forma de
                  acuerdo a los manuales de mantenimiento preventivo
                </Col>
                <Col xs={12} className="mt-5">
                  Mediante los procesos de concientización del personal
                  capacitado y proveedores calificados, se llevan a cabo la
                  ejecución de los servicios en un marco del cuidado del medio
                  ambiente mediante la implementación de la norma ISO 14001:2015
                </Col>
              </Row>
            </Col>
          </Row>
        </ShowAt>
        <ShowAt breakpoint="largeAndBelow">
          <Row className="d-flex m-0 p-0 mt-4">
            <Col xs={12}>
              Para preservar la vida útil del vehículo y fomentar el cuidado del
              medio ambiente Toyota Prana se compromete y comunica su política
              ambiental a todos sus clientes y proveedores.
            </Col>
            <Col xs={12} className="my-3">
              Durante toda la cadena de valor Toyota Prana se compromete
              mediante una gestión responsable al cuidado y minimización de los
              recursos. Tratando sus residuos líquidos y sólidos
              responsablemente.
            </Col>
          </Row>
          <Row className="d-flex m-0 p-0">
            <Col xs={12}>
              Informando los beneficios del vehículo Toyota Argentina S.A.
              recomienda al cliente gestionar el mantenimiento del mismo en
              concesionarias oficiales de la red, en tiempo y forma de acuerdo a
              los manuales de mantenimiento preventivo
            </Col>
            <Col xs={12} className="mt-3">
              Mediante los procesos de concientización del personal capacitado y
              proveedores calificados, se llevan a cabo la ejecución de los
              servicios en un marco del cuidado del medio ambiente mediante la
              implementación de la norma ISO 14001:2015
            </Col>
          </Row>
        </ShowAt>

        <Row className="m-0 p-0 mt-4">
          <Col xs={12} className="text-center pt-1 mb-3">
            <h3>El proceso de "Mejora contínua"</h3>
          </Col>
        </Row>
        <Row className="m-0 p-0 mt-4">
          <Col xs={12}>
            <ul>
              <li>
                <b>
                  Toyota trabaja en contemplar el impacto ambiental del vehículo
                  a lo largo de todo su ciclo de vida en conjunción con lo
                  estipulado por la casa matriz.
                </b>
                <br />
                El concesionario ha implementado la Norma ISO 14001:2015
                basándose en la metodología del ciclo PVHA.
              </li>
              <br />
              <li>
                <b>Planificar</b>
                <br />
                Estableciendo objetivos y procesos necesarios para conseguir
                resultados de acuerdo a la Política Ambiental de la
                organización.
              </li>
              <br />
              <li>
                <b>Verificar</b>
                <br />
                Realizando el seguimiento y la medición de los procesos respecto
                a la política ambiental, los objetivos, las metas y los
                requisitos legales asociados a la organización.
              </li>
              <br />
              <li>
                <b>Hacer</b>
                <br />
                Implementando los procesos.
              </li>
              <br />
              <li>
                <b>Actuar</b>
                <br />
                Tomando acciones para mejorar continuamente el desempeño
                ambiental.
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="m-0 p-0 mt-5">
          <Col xs={12} className="text-center pt-1 mb-3">
            <h3>Política Ambiental</h3>
          </Col>
        </Row>
        <Row className="m-0 p-0 mt-4">
          <Col xs={12}>
            <ul>
              <li>
                <b>POLITICA AMBIENTAL</b>
                <br />
                Toyoya Prana, Concesionario Oficial Toyota, dedicado a la
                Administración y Ventas de Vehiculos 0 Km. Ventas de Repuestos y
                accesorios, tareas de mantenimiento y reparación inherentes a
                los vehiculos, lavado de cortesia, fija la presente Política
                Ambiental con el objetivo de contribuir al cuidado del Medio
                Ambiente por lo que se compromete a:
              </li>
              <br />
              <li>
                <b>Mejora Continua</b>
                <br />
                Desarrollar y promover mejoras ambientales continuas mediante la
                implementación de planes voluntarios, estableciendo metas y
                objetivos progresivos.
              </li>
              <br />
              <li>
                <b>Protección del medio ambiente</b>
                <br />
                Utilizar tecnologías ambientales, económicamente viables de
                implementar, con el propósito de minimizar impactos potenciales,
                incluidos la prevención de la contaminación y otros compromisos,
                pertinentes al contexto de la organización.
              </li>
              <br />
              <li>
                <b>Cumplimientos legales y otros requisitos</b>
                <br />
                Cumplir con Requisitos Legales vinculados a los Aspectos
                Ambientales Significativos desde la perspectiva del ciclo de
                vida y otros requerimientos a los que AUTOPRANA S.A. adhiere.
              </li>
              <br />
              <li>
                <b>Control de los recursos.</b>
                <br />
                Controlar y hacer uso responsable de los recursos utilizados y
                gestionar adecuadamente los residuos generados.
              </li>
              <br />
              <li>
                <b>Concientización Ambiental.</b>
                <br />
                Capacitar y concientizar al personal en el uso de prácticas
                tendientes a minimizar los impactos ambientales asociados con el
                trabajo.
              </li>
              <br />
              <li>
                <b>Comunicación.</b>
                <br />
                Comunicar esta Política a todo interesado que así lo solicite.
              </li>
              <br />
              <li>
                <b>Partes Interesadas</b>
                <br />
                Difundir estas políticas e integrar en las mismas a las partes
                interesadas como un compromiso activo.
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="m-0 p-0 mt-5">
          <Col xs={12} className="text-center pt-1 mb-3">
            <h3>GRI</h3>
          </Col>
          <Col xs={12} className="text-center pt-1 mb-3">
            Reporte de sostenibilidad 2021
          </Col>
        </Row>
        <Row className="m-0 p-0 mt-1 justify-content-center">
          <Col xs={6} lg={3}>
            <a
              href="https://www.autoprana.com.ar/images/homeNews/sustentabilidad.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={gri} width="100%" />
            </a>
          </Col>
        </Row>
        <Row className="m-0 p-0 mt-1">
          <Col xs={12} className="text-center pt-1 mb-3">
            sustentabilidad.pdf (PDF: 7.3MB)
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
