import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

export default function useGastos() {
  const [items, setItems] = useState(localStorage);
  const [patentamientoSeleccionado, setPatentamientoSeleccionado] = useState({
    tipo: "sin gastos",
    valor: 0,
    aGuardarEnBase: "sin gastos",
  });
  const [lgShow, setLgShow] = useState(false);
  const [terminos, setTerminos] = useState(false);
  const [productReadytoPay, setProductReadytoPay] = useState([]);
  console.log("🚀 ~ productReadytoPay:", productReadytoPay);

  const location = useLocation();
  let history = useHistory();

  const product = location.state
    ? location.state.product
    : items.length > 0
    ? items[0]
    : history.push("/");

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("items"));
    if (items) {
      setItems([items]);
    }
  }, []);

  //console.log(items[0], "desde useusados");

  const handlePatentamientoSeleccionado = (patentamiento) => {
    setPatentamientoSeleccionado(patentamiento);
  };

  const handleModal = (value) => {
    setLgShow(value);
  };

  const handleTerminos = (value) => {
    setTerminos(value);
  };

  const handleProductReadytoPay = () => {
    setProductReadytoPay({
      id: product.id,
      modelo: product.modelo,
      motor: product.motor,
      foto: product.foto0,
      servicio: "",
      precio: product.reserva_min,
      moneda: product.moneda,
      origen: "ventas",
      sucursal: "",
      turno: "",
      patentamiento: patentamientoSeleccionado.aGuardarEnBase,
    });
  };

  return {
    product,
    patentamientoSeleccionado,
    handlePatentamientoSeleccionado,
    handleModal,
    lgShow,
    terminos,
    handleTerminos,
    productReadytoPay,
    handleProductReadytoPay,
  };
}
