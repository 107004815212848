import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import modelos_mock from "../../../mocks/kinto_mock.json";
import * as yup from "yup";

export default function useKinto() {
  let history = useHistory();

  const [modelos, setModelos] = useState(modelos_mock); //cambiar por []
  const [spinner, setSpinner] = useState(false); //poner en true
  //console.log("🚀 ~ modelos:", modelos);

  /* 
  ver custom css para flechas en izquierda y derecha custom.
  tambien es necesario agregar jquery en el index.html
  */
  const options = {
    loop: true,
    center: true,
    items: 3,
    margin: 0,
    autoplay: true,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: true,
    navText: [
      "<div class='nav-btn prev-slide'> < </div>",
      "<div class='nav-btn next-slide'> > </div>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  useEffect(() => {
    var url = `https://dash.autoprana.com.ar/clamaapi/v2/kinto/kinto_flota`;

    fetch(url)
      .then((data) => {
        return data.json();
      })
      .then((modelos) => {
        setModelos(modelos.data);
        setSpinner(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const justletterRegex = /^([a-zA-Z ])/;
  const numberRegex = /^[0-9]+$/;
  const kintoProducts = [
    {
      id: 1,
      name: "Kinto Share",
    },
    {
      id: 2,
      name: "Kinto One Personal",
    },
    {
      id: 3,
      name: "Kinto One Fleet",
    },
  ];

  const advanceSchema = yup.object().shape({
    nombre: yup
      .string()
      .matches(justletterRegex, "Solo se permiten letras")
      .trim()
      .required("El nombre es requerido"),
    correo: yup
      .string()
      .email("Ingrese un correo valido")
      .trim()
      .required("El correo es requerido"),
    telefono: yup
      .string()
      .matches(numberRegex, { message: "Solo numeros" })
      .min(7, "El numero de telefono debe tener como minimo 7 digitos")
      .max(10, "El numero de telefono debe tener como maximo 10 digitos")
      .trim()
      .required("El numero de telefono es requerido"),
    kintoProducts: yup
      .string()
      .oneOf(["Kinto Share", "Kinto One Personal", "Kinto One Fleet"])
      .required("El producto es requerido"),
    modelo: yup.string().required("El modelo es requerido"),
    consulta: yup.string().required("La consulta es requerida"),
  });

  const onSubmit = async (values, actions) => {
    const url = `https://dash.autoprana.com.ar/clamaapi/v2/kinto/consulta`;

    await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        console.log(data);
        console.log("enviado correctamente");

        Swal.fire({
          icon: "success",
          title: "Consulta enviada correctamente",
          text: "En breve recibira un contacto de nuestro responsable KINTO",
          showConfirmButton: true,
          confirmButtonText: "Aceptar",
        });
        history.push("/");
      })
      .catch((error) => {
        console.log(error);
        console.log("error");
        Swal.fire({
          icon: "error",
          title: "Error al enviar su consulta",
          text: "Por favor comuniquese al 4505-8700, muchas gracias",
          /* text: "verifique los datos ingresados y si cuenta con autorizacion de su tarjeta para realizar la compra en nuestro store. <br> Visa autorizaciones 4379-3400 <br> Mastercard autorizaciones 4379-3401 <br> Amex autorizaciones 4379-3402 <br> Naranja autorizaciones 4379-3403 <br>", */
          showConfirmButton: true,
          confirmButtonText: "Aceptar",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    actions.setSubmitting(false);
  };

  return {
    modelos,
    options,
    advanceSchema,
    onSubmit,
    kintoProducts,
    justletterRegex,
    numberRegex,
    spinner,
  };
}
