export const socialMedia = {
  facebook: "https://www.facebook.com/PranaToyota/",
  instagram: "https://www.instagram.com/toyotaprana/",
  youtube: "https://www.youtube.com/c/AutopranaArToyota/videos",
  linkedin: "https://ar.linkedin.com/company/toyota-prana",
  tiktok: "https://www.tiktok.com/@toyotaprana",
};

export const imgPath = "https://store.autoprana.com.ar/img/imgDefault/";
export const imagPathUsados = "https://store.autoprana.com.ar/img/";

export const MARCAS = {
  TOYOTA: "TOYOTA",
  TOYOTA_CERTIFICADO: "TOYOTA CERTIFICADOS",
  OTRAS: "OTRAS MARCAS",
};

export const capitalize = (text) =>
  text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);

export const truncate = (text, length) =>
  text && text.length > length ? `${text.substring(0, length)}` : text;
