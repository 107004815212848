import React, { Component } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class MyDatepickerComponent extends Component {
  state = {
    startDate: this.props.rawDateTime
      ? this.props.rawDateTime
      : new Date().setDate(new Date().getDate() + 10),
  };

  handleSelect = (date, event) => {
    //console.log("onSelect", date, event);
  };
  handleChange = (date, event) => {
    //console.log("onChange", date, event);
    this.setState({
      startDate: date,
    });
    this.props.handleDateTime(date);
    this.props.handleShow(true);
    this.props.handleTime(date);
  };
  handleBlur = (event) => {
    //console.log("onBlur", event);
  };
  handleFocus = (event) => {
    //console.log("onFocus", event);
  };
  handleCalendarClose = () => {
    //console.log("CalendarClose");
  };
  handleCalendarOpen = () => {
    //console.log("CalendarOpen");
  };
  handleWeekSelect = (firstDayOfWeek, weekNumber, event) => {
    console.log("on", firstDayOfWeek, weekNumber, event);
  };
  handleMonthChange = (date) => {
    console.log("onMonthChange", date);
  };
  handleYearChange = (date) => {
    console.log("onYearChange", date);
  };
  handleDayMouseEnter = (date) => {
    //console.log("onDayMouseEnter", date);
  };

  filterDays = (date) => {
    // Disable Weekends
    if (date.getDay() === 0 || date.getDay() === 6) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    let dt = new Date();
    const maxDate = dt.setDate(dt.getDate() + 60);
    const minDate = dt.setDate(dt.getDate() - 50);

    let horariosNoDisponibles = this.props.horariosNoDisponibles;

    horariosNoDisponibles &&
      (horariosNoDisponibles = horariosNoDisponibles[0].split(" "));

    const getHorariosDisponibles = () =>
      horariosNoDisponibles.map((item) => {
        let horas = item.substring(0, 2);
        let minutos = item.substring(3, 5);

        return new Date().setHours(horas, minutos, 0);
      });

    //const includeDatesArray = [new Date("01-21-2023"), new Date("01-24-2023")];

    return (
      <>
        <div>
          <DatePicker
            onSelect={() => this.handleSelect}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onCalendarClose={this.handleCalendarClose}
            onCalendarOpen={this.handleCalendarOpen}
            onWeekSelect={this.handleWeekSelect}
            onMonthChange={this.handleMonthChange}
            onYearChange={this.handleYearChange}
            onDayMouseEnter={this.handleDayMouseEnter}
            selected={this.state.startDate}
            showTimeSelect={this.props.show}
            dateFormat="dd/MM/yyyy  EE hh:mm a"
            locale="en"
            maxDate={maxDate}
            minDate={minDate}
            /* includeDates={includeDatesArray} */
            filterDate={this.filterDays}
            open={true} //ver alguna funcion que prenda y apague para reposicionar si se dispara la sidebar
            popperPlacement="bottom"
            timeIntervals={30}
            minTime={new Date().setHours(8, 0, 0)}
            maxTime={new Date().setHours(12, 30, 0)}
            showDisabledMonthNavigation={false}
            excludeTimes={horariosNoDisponibles && getHorariosDisponibles()}
          />
        </div>
      </>
    );
  }
}

export default MyDatepickerComponent;

/* 
https://www.freakyjolly.com/react-datepicker-using-react-datepicker/

*/
