import React from "react";

import useServicios from "./hooks/useServicios";

import { Row, Col } from "react-bootstrap";

export default function Servicios() {
  const { getModelos } = useServicios();

  window.scrollTo(0, 0);

  return (
    <>
      <Row className="m-0 p-0">
        <Col xs={12} md={{ span: 8, offset: 1 }} className="my-5">
          <h2>Selecciona el modelo de tu vehículo</h2>
        </Col>
      </Row>
      <Row className="m-0 p-0 mb-5">
        <Col xs={12} md={{ span: 10, offset: 1 }}>
          <Row className="m-0 p-0">{getModelos()}</Row>
        </Col>
      </Row>
    </>
  );
}
