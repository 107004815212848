import React from "react";
//import Card from "react-credit-card-component/dist/mjs/index.d.ts";
import Card from "./index";
//import "react-credit-card-component/dist/styles-compiled.css";
import "./styles.css";

export default class PaymentForm extends React.Component {
  state = {
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
  };

  render() {
    return (
      <div id="PaymentForm" onClick={() => this.props.handleTurnCard()}>
        <Card
          cvc={this.props.codigo}
          focused={this.props.focus}
          expiry={this.props.vencimientoMes + this.props.vencimientoAnio}
          name={this.props.nombre}
          number={this.props.numeroTarjeta}
          preview={true}
          issuer={this.props.tarjeta}
        />
      </div>
    );
  }
}
