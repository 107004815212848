import React from "react";
import { HashRouter as Router, Route } from "react-router-dom";

import "core-js/es6/promise";
import "core-js/es6/set";
import "core-js/es6/map";

import Layout from "./components/common/Layout";
import Planes from "./components/Planes";
import Ficha from "./components/Planes/Ficha";
import Servicios from "./components/servicios";
import SeleccionServicio from "./components/servicios/SeleccionServicio";
import SeleccionSucursal from "./components/servicios/SeleccionSucursal";
import SeleccionTurno from "./components/servicios/SeleccionTurno";
import Tarjetas from "./components/tarjetas";
import Home from "./components/home/Home";
import SeleccionUsadosMarca from "./components/usados";
import Catalogo from "./components/usados/Catalogo";
import Fotos from "./components/usados/Fotos";
import Gastos from "./components/usados/Gastos";
import CatalogoAccesorios from "./components/accesorios";
import useApp from "./hooks/useApp";
import CatalogoRepuestos from "./components/repuestos";
import Contacto from "./components/contacto/Contacto";
import Sucursales from "./components/sucursales/Sucursales";
import PolitaAmbiental from "./components/seccionesSecundarias/PolitaAmbiental";
import Kinto from "./components/kinto";
import AccesoriosFotos from "./components/accesorios/AccesoriosFotos";
import AccesoriosContext from "./components/accesorios/context/AccesoriosContext";
import RepuestosContext from "./components/repuestos/context/RepuestosContext";
import RepuestosFotos from "./components/repuestos/RepuestosFotos";

function App() {
  const { loaded } = useApp();

  return (
    <>
      {loaded && (
        <Router>
          <Layout>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/servicios">
              <Servicios />
            </Route>
            <Route exact path="/servicios/seleccionServicio">
              <SeleccionServicio />
            </Route>
            <Route exact path="/servicios/seleccionSucursal">
              <SeleccionSucursal />
            </Route>
            <Route exact path="/servicios/seleccionTurno">
              <SeleccionTurno />
            </Route>
            <Route exact path="/planes">
              <Planes />
            </Route>
            <Route exact path="/planes/ficha">
              <Ficha />
            </Route>
            <Route exact path="/tarjetas">
              <Tarjetas />
            </Route>
            <Route exact path="/usados">
              <SeleccionUsadosMarca />
            </Route>
            <Route exact path="/usados/catalogo">
              <Catalogo />
            </Route>
            <Route exact path="/usados/catalogo/fotos">
              <Fotos />
            </Route>
            <Route exact path="/usados/catalogo/gastos">
              <Gastos />
            </Route>

            <AccesoriosContext>
              <Route exact path="/accesorios">
                <CatalogoAccesorios />
              </Route>
              <Route exact path="/accesorios/accesoriosFotos">
                <AccesoriosFotos />
              </Route>
            </AccesoriosContext>

            <RepuestosContext>
              <Route exact path="/repuestos">
                <CatalogoRepuestos />
              </Route>
              <Route exact path="/repuestos/repuestosFotos">
                <RepuestosFotos />
              </Route>
            </RepuestosContext>

            <Route exact path="/contacto">
              <Contacto />
            </Route>
            <Route exact path="/sucursales">
              <Sucursales />
            </Route>
            <Route exact path="/politicaAmbiental">
              <PolitaAmbiental />
            </Route>
            <Route exact path="/kinto">
              <Kinto />
            </Route>
          </Layout>
        </Router>
      )}
    </>
  );
}

/* 
Continue:

*/

export default App;
